<template>
  <div class="container">
    <div class="content-box">
      <div class="main">
        <div class="avatar" @click="handleAvatar">
          <el-avatar
            v-if="userInfo.headUrl"
            :src="imgSrc + userInfo.headUrl"
          ></el-avatar>
          <el-avatar
            v-else
            src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
          ></el-avatar>
        </div>
        <div class="userinfo">
          <span class="info-name">{{ userInfo.userName }}</span>
          <span class="info-sex" v-if="roleKey == 'teacher' || roleKey == 'parents' || roleKey == 'student'">{{ userInfo.sex == "1" ? "男" : "女" }}</span>
        </div>
        <div class="school">
          <span class="school-name" v-if="roleKey == 'teacher' || roleKey == 'ban_wei' || roleKey == 'xin_li_plan'">{{
            userInfo.dept.deptName
          }}</span>
          <span class="school-name" v-if="roleKey == 'student'">{{
            userInfo.dept.parentName
          }}</span>
          <span class="school-class" v-if="roleKey == 'student'">{{
            userInfo.dept.deptName
          }}</span>
        </div>

        <!-- 教师 start -->
        <el-row v-if="roleKey == 'teacher'">
          <el-col :span="12" style="border-right: 1px solid #ccdef4">
            <div class="message">
              <span>手机号码：{{userInfo.phoneNumber}}</span>
              <span style="margin-left: 20px">
                <!-- <el-button size="mini" type="primary" @click="handlePhone"
                  >修改电话</el-button
                > -->
              </span>
            </div>
            <div class="message">账号状态：<span>正常</span></div>
            <div class="btn">
              <el-button
                style="width: 280px"
                size="medium"
                type="primary"
                @click="handlePass"
                >修改密码</el-button
              >
            </div>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
        <!-- 教师 end -->

        <el-row v-if="roleKey == 'student' || roleKey == 'parents'">
          <el-col :span="12" style="border-right: 1px solid #ccdef4">
            <div class="message">身份证号：{{ userInfo.card }}</div>
            <div class="message">学籍号（账号）：{{ userInfo.loginName }}</div>
            <div class="message">
              <span>手机号码：{{ userInfo.phoneNumber }}</span>
              <!-- <span style="margin-left: 20px">
                <el-button v-if="roleKey == 'student'" size="mini" type="primary" @click="handlePhone"
                  >修改电话</el-button
                >
              </span> -->
            </div>
            <div class="message">班级职务：{{ userInfo.studentPosition }}</div>
            <div class="message">学生状态：{{ userInfo.studentStatus }}</div>
            <div class="message">
              账号状态：{{ userInfo.status == "0" ? "正常" : "冻结" }}
            </div>
            <div class="message">是否为特殊学生：{{ specialStatus }}</div>
            <div class="btn">
              <el-row :gutter="20" style="height: auto !important">
                <el-col :span="12">
                  <el-button
                    style="width: 280px"
                    size="medium"
                    type="primary"
                    @click="handlePass"
                    >修改密码</el-button
                  >
                </el-col>
                <el-col :span="12" v-if="specialStatus == '否' && roleKey == 'student'">
                  <el-button
                    style="width: 280px"
                    size="medium"
                    type="primary"
                    @click="handleApply"
                    >申请特殊学生</el-button
                  >
                </el-col>
                <el-col :span="12" v-if="specialStatus == '审核中' && roleKey == 'student'">
                  <el-button
                    style="width: 280px"
                    size="medium"
                    type="primary"
                    @click="handleApplyDetail"
                    >申请详情</el-button
                  >
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="12" style="padding-top: 0" v-if="roleKey == 'student'">
            <div style="position: relative;">
              <div class="class-chart" ref="circle"></div>
              <div class="chart-show">
                <h2>{{ userCreditBo.curCredit }}<span>分</span></h2>
                <p>学生信用积分</p>
              </div>
            </div>
            <div style="padding-left: 37px">
              <p>信用记录</p>
              <el-table
                :data="tableData"
                style="width: 100%"
                max-height="150px"
                border
                :header-cell-style="tableHeaderColor"
                :cell-style="cellStyleFun"
              >
                <el-table-column type="index" label="序号" width="50"> </el-table-column>
                <el-table-column prop="content" label="记录内容"> </el-table-column>
                <el-table-column prop="subScore" label="扣分值" width="120">
                </el-table-column>
                <el-table-column prop="createTime" label="时间" width="150">
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
		<el-row v-if="roleKey == 'ban_wei' || roleKey == 'xin_li_plan'">
			<el-col :span="12" style="border-right: 1px solid #ccdef4">
				<div class="btn">
				  <el-row :gutter="20" style="height: auto !important">
				    <el-col :span="12">
				      <el-button
				        style="width: 280px"
				        size="medium"
				        type="primary"
				        @click="handlePass"
				        >修改密码</el-button
				      >
				    </el-col>
				  </el-row>
				</div>
			</el-col>
		</el-row>
      </div>
    </div>

    <!-- 修改电话 -->
    <el-dialog
      title="修改绑定电话"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="open"
      center
      width="600px"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="新电话" prop="phoneNumber">
          <el-input v-model="form.phoneNumber"></el-input>
        </el-form-item>
        <el-form-item label="图形验证码" prop="code">
          <el-row type="flex" align="middle">
            <el-col
              ><el-input
                v-model="form.code"
                style="width: 240px; margin-right: 10px"
              ></el-input
            ></el-col>
            <el-col
              ><div style="height: 40px" @click="refreshCode()">
                <Identify :identifyCode="identifyCode" /></div
            ></el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="短信验证码" prop="smsYzm">
          <el-input
            v-model="form.smsYzm"
            style="width: 240px; margin-right: 10px"
          ></el-input>
          <el-button
            type="primary"
            plain
            style="width: 112px"
            :disabled="isClick"
            @click="getCode"
            >{{ content }}</el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="open = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmitPhone">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="openPass"
      center
      width="600px"
    >
      <el-form
        ref="formPass"
        :model="form"
        :rules="rules"
        label-width="90px"
        label-position="left"
      >
        <el-form-item label="原密码" prop="oldPassword">
          <el-input
            v-model.trim="form.oldPassword"
            show-password
            style="width: 220px; margin-right: 10px"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model.trim="form.newPassword"
            show-password
            style="width: 220px; margin-right: 10px"
          ></el-input>
          <span>（8位包含大小写字母和数字）</span>
        </el-form-item>
        <el-form-item label="确认密码" prop="password">
          <el-input
            v-model.trim="form.password"
            show-password
            style="width: 220px; margin-right: 10px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="openPass = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmitPass">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 上传头像 -->
    <el-dialog
      title="更换头像"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="openAvatar"
      center
      width="600px"
    >
      <el-form
        :model="form"
        style="border-top: 1px solid #c5c8d0; border-bottom: 1px solid #c5c8d0"
      >
        <el-form-item style="margin-top: 16px">
          <el-row :gutter="20">
            <el-col :span="12" style="text-align: center">
              <el-avatar
                v-if="userInfo.headUrl"
                :src="imgSrc + userInfo.headUrl"
              ></el-avatar>
              <el-avatar
                v-else
                src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              ></el-avatar>
            </el-col>
            <el-col :span="12" style="text-align: center">
              <el-upload
                class="avatar-uploader"
                action="nothing"
                :show-file-list="false"
                :on-change="handleUpAvatar"
                :auto-upload="false"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="openAvatar = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmitAvatar">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 申请成为特殊学生 -->
    <el-dialog
      title="申请特殊学生"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="openSepcial"
      center
      width="600px"
    >
      <el-form :model="form" :rules="rules" ref="formSpecial">
        <el-form-item label="申请类别：" prop="specialType">
          <el-select
            v-model="form.specialType"
            placeholder="申请类别"
            style="width: 240px"
          >
            <el-option
              v-for="item in specialList"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请原因：" prop="specialCause">
          <el-input v-model="form.specialCause" style="width: 240px"></el-input>
        </el-form-item>
        <el-form-item label="佐证材料：">
          <el-upload
            action="nothing"
            accept="image/*"
            list-type="picture-card"
            :limit="3"
            :file-list="form.fileList"
            :on-change="handleUpChange"
            :on-remove="handleRemove"
            :on-exceed="handleExceed"
            :auto-upload="false"
          >
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              <!-- 只能上传jpg/png文件，且不超过1MB，最多三张 -->
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="补充说明：" prop="specialRemark">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            v-model="form.specialRemark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="openSepcial = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmitSpecial">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 申请详情 -->
    <el-dialog
      title="申请详情"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :visible.sync="openDetail"
      center
      width="600px"
    >
      <el-form :model="form">
        <el-form-item label="申请类别：">
          <span>{{ form.specialType }}</span>
        </el-form-item>
        <el-form-item label="申请原因：">
          <span>{{ form.specialCause }}</span>
        </el-form-item>
        <el-form-item label="佐证材料：">
          <el-image
            v-for="(item, index) in form.proofFile"
            :key="index"
            :src="imgSrc + item"
          ></el-image>
        </el-form-item>
        <el-form-item label="补充说明：">
          <span>{{ form.specialRemark }}</span>
        </el-form-item>
        <el-form-item label="审核状态：">
          <span>{{ form.specialStatus }}</span>
        </el-form-item>
        <el-form-item label="驳回原因：" v-if="form.refuseCause">
          <span>{{ form.refuseCause }}</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="openDetail = false">关闭</el-button>
        <el-button type="primary" @click="handleCancel">撤销申请</el-button>
        <el-button type="primary" @click="handleReapply">重新申请</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Identify from "@/components/Identify";
import {
  updatePhone,
  getPhoneCode,
  updatePassword,
  uploadImg,
  applySpecial,
  applySpecialDetail,
  reApplySpecial,
  cancelApplySpecial,
  applySpecialStatus,
  getCredit,
} from "@/api/public/personalCenter";
import {getUserinfo} from '@/api/public/login'
import { getDic } from "@/api/public/search";
export default {
  data() {
    var validPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    var validPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("密码不能为空"));
      } else {
        // let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,12}$/;
        let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8}$/
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("密码为8位字母数字组合"));
        }
      }
    };
    var validSurePass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("密码不能为空"));
      } else {
        // let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,12}$/;
        let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8}$/
        if (reg.test(value)) {
          if (this.form.newPassword) {
            if (value == this.form.newPassword) {
              callback();
            } else {
              return callback(new Error("两次输入的密码不一致"));
            }
          }
        } else {
          return callback(new Error("密码为8位字母数字组合"));
        }
      }
    };
    var validCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("图形验证码不能为空"));
      } else {
        if (this.identifyCode == value) {
          callback();
        } else {
          this.refreshCode();
          return callback(new Error("图形验证码有误"));
        }
      }
    };
    return {
      imgSrc: this.imageSrc(),
      userInfo: {
        dept: {},
      },
      open: false,
      openPass: false,
      openAvatar: false,
      openSepcial: false,
      openDetail: false,
      specialList: [],
      tableData: [],
      specialStatus: "",
      imageUrl: "",
      userCreditBo: {},
      roleKey: "",
      formSpare: {},
      form: {},
      rules: {
        // 修改手机
        phoneNumber: [
          { required: true, trigger: "blur", validator: validPhone },
        ],
        code: [{ required: true, trigger: "blur", validator: validCode }],
        smsYzm: [
          { required: true, message: "短信验证码不能为空", trigger: "blur" },
        ],

        // 修改密码
        oldPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, trigger: "blur", validator: validPass },
        ],
        password: [
          { required: true, trigger: "blur", validator: validSurePass },
        ],

        // 申请特殊学生
        specialType: [
          { required: true, message: "请选择申请类别", trigger: "change" },
        ],
        specialCause: [
          { required: true, message: "请输入申请原因", trigger: "blue" },
        ],
      },
      rulesPass: {},
      rulesSpecial: {},
      content: "发送验证码",
      isClick: false,
      identifyCode: "",
      identifyCodes: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
    };
  },
  components: { Identify },
  created() {
    if (this.$store.state.user.roles.length > 0) {
      this.roleKey = this.$store.getters.roles[0].roleKey;
      if(this.roleKey == 'student') {
        this.getUserInfo();
        this.getStatus();
      }else if(this.roleKey == 'parents') {
        this.getStudentInfo()
        this.getStatus();
      }else if(this.roleKey == 'teacher') {
        this.getUserInfo();
      } else if (this.roleKey == 'ban_wei' || this.roleKey == 'xin_li_plan') {
		this.getUserInfo(true)
	  }
    }
    if (this.roleKey == "student") {      
      this.userCreditBo = JSON.parse(sessionStorage.getItem("circle"));
      this.$nextTick(() => {
        this.initCircle(this.userCreditBo);
      });
    }
  },
  methods: {
    async getUserInfo(notQryCredit) {
      let res = await this.$store.dispatch("user/getUserInfo")
      this.userInfo = res.data
	  if (!notQryCredit) {
		getCredit({userId: this.userInfo.userId}).then(res => {
		  this.tableData = res.data
		})  
	  }
    },
    async getStudentInfo() {
      await getUserinfo({}).then(res =>{
        this.userInfo = res.data;
        if(this.userInfo.studentStatus) {
          this.userInfo.studentStatus = this.userInfo.studentStatus.split(',')[0]
        }
        // console.log(this.userInfo);
      })
    },
    getStatus() {
      applySpecialStatus({ userId: this.$store.getters.userId }).then((res) => {
        // console.log(res);
        this.specialStatus = res.data;
      });
    },
    handlePhone() {
      this.reset();
      this.open = true;
      this.$nextTick(() => {
        this.refreshCode();
      });
    },
    getCode() {
      let time = 60;
      this.isClick = true;
      this.content = time + "s";
      let ti = setInterval(() => {
        time--;
        this.content = time + "s";
        if (time === 0) {
          this.content = "发送验证码";
          this.isClick = false;
          clearInterval(ti);
        }
      }, 1000);
      getPhoneCode({}).then((res) => {});
    },
    handleSubmitPhone() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          updatePhone(this.form).then((res) => {
            this.$message.success("修改成功");
            this.open = false;
            this.getUserInfo();
          });
        } else {
          return false;
        }
      });
    },
    handlePass() {
      this.reset();
      this.openPass = true;
      this.$nextTick(() => {
        this.refreshCode();
      });
      this.form.userId = this.userInfo.userId;
    },
    handleSubmitPass() {
      this.$refs["formPass"].validate((valid) => {
        if (valid) {
          updatePassword(this.form).then((res) => {
            this.openPass = false;
            this.$alert("密码修改成功，请重新登录", "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                this.$store.dispatch("user/logout").then(() => {
                  this.$router.push("/login");
                });
              },
            });
          });
        } else {
          return false;
        }
      });
    },
    handleApply() {
      this.getSpecialType();
      this.openSepcial = true;
      this.form.userId = this.userInfo.userId;
    },
    handleSubmitSpecial() {
      if(this.form.file) {
        this.form.file = this.compressImg(this.form.file)
      }
      setTimeout(()=> {
        this.$refs["formSpecial"].validate((valid) => {
          if (valid) {
            var formData = new FormData();
            if (this.form.file != undefined) {
              this.form.file.map((item) => {
                formData.append("file", item);
              });
            }
            formData.append("userId", this.form.userId);
            formData.append("specialType", this.form.specialType);
            formData.append("specialCause", this.form.specialCause);
            formData.append("specialRemark", this.form.specialRemark);
            applySpecial(formData).then((res) => {
              this.$message.success("申请成功，等待审核");
              this.openSepcial = false;
              this.getStatus();
            });
          } else {
            return false;
          }
        });
      }, 1000)
    },
    // 申请详情
    handleApplyDetail() {
      applySpecialDetail({ userId: this.$store.getters.userId }).then((res) => {
        // console.log(res);
        this.form = res.data;
        this.formSpare = this.form;
        this.openDetail = true;
        this.form.proofFile = this.form.proofFile.split(",");
      });
    },
    // 撤销申请
    handleCancel() {
      this.$confirm(this.form.specialStatus + ",确定撤销申请吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cancelApplySpecial({ userId: this.$store.getters.userId }).then(
          (res) => {
            this.$message.success("撤销成功");
            this.openDetail = false;
            this.getStatus();
            this.reset();
          }
        );
      });
    },
    // 重新申请
    handleReapply() {
      this.$confirm(this.form.specialStatus + ",确定重新申请吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.form.specialType == "残疾") {
            this.form.specialType = "0";
          } else if (this.form.specialType == "受伤") {
            this.form.specialType = "1";
          }
          this.openDetail = false;
          this.handleApply();
        })
        .catch(() => {
          return false;
        });
    },
    handleAvatar() {
      if(this.roleKey != 'parents') {
        this.openAvatar = true;
      }
    },
    // 头像上传
    handleUpAvatar(file, fileList) {
      var _this = this;
      const isJPG = file.raw.type === "image/jpeg";
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是JPG格式！");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过2MB！");
      }
      if (isJPG && isLt2M) {
        this.imageUrl = URL.createObjectURL(file.raw);
        for (let i = 0; i < fileList.length; i++) {
          if (fileList[i].size / 1024 > 1025) {
            this.photoCompress(
              file.raw,
              { quality: 0.2 },
              function (base64Codes) {
                var bl = _this.convertBase64UrlToBlob(base64Codes);
                // console.log(bl);
                const files = new window.File([bl], fileList[i].name, {
                  type: fileList[i].type,
                });
                _this.form.file = files;
              }
            );
          } else {
            _this.form.file = fileList[i].raw;
          }
        }
      }
    },
    handleSubmitAvatar() {
      var formData = new FormData();
      formData.append("file", this.form.file);
      formData.append("userId", this.userInfo.userId);
      uploadImg(formData).then((res) => {
        this.$message.success("头像上传成功");
        this.openAvatar = false;
        this.imageUrl = "";
        this.$store.dispatch('user/getAgain').then(()=> {
			if (this.roleKey == 'ban_wei' || this.roleKey == 'xin_li_plan') {
				this.getUserInfo(true)
			} else {
				this.getUserInfo()
			}
        })
      });
    },
    reset() {
      this.resetForm("form");
      this.form = {
        phoneNumber: undefined,
        code: undefined,
        smsYzm: undefined,

        oldPassword: undefined,
        newPassword: undefined,
        password: undefined,

        specialType: undefined,
        specialCause: undefined,
        specialRemark: undefined,
      };
    },
    // 上传图片
    handleUpChange(file, fileList) {
      // console.log(file);
      // console.log(fileList);
      var _this = this;
      _this.form.file = fileList;
      if(file.size / 1024 > 5 * 1024) {
        this.$message.warning('图片大小不得超过5MB')
        this.handleRemove(file)
      }
      // console.log(_this.form.file, "file");
    },
    handleRemove(file, fileList) {
      this.form.file.forEach((item, index) => {
        if (item.name === file.raw.name) {
          this.form.file.splice(index, 1);
        }
      });
    },
    handleExceed() {
      this.$message.warning("最多只能上传三张图片");
    },
    // 生成随机数
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 更新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
      // console.log("当前验证码:", this.identifyCode);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      // console.log('data, len:', data, len)
      for (let i = 0; i < len; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length - 1)];
      }
    },
    getSpecialType() {
      getDic({ type: "specialStatus" }).then((res) => {
        this.specialList = res.data;
      });
    },
    initCircle(userCreditBo) {
      const e = this.$refs.circle;
      var myChart = this.$echarts.init(e);
      myChart.setOption({
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "3%",
          left: "center",
        },
        color: ["#F7A319", "#198AF7"],
        series: [
          {
            // hoverAnimation:false,
            // name: '访问来源',
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "20",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: userCreditBo.subCredit, name: "扣除积分" },
              { value: userCreditBo.curCredit, name: "剩余积分" },
            ],
          },
        ],
      });
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .content-box {
    background: rgba(206, 223, 236, 0.45);
    padding: 70px 18px 18px 18px;
    border-radius: 0 0 6px 6px;
    .main {
      width: 100%;
      height: 647px;
      background: #ffffff;
      border: 1px solid rgba(153, 153, 153, 0.15);
      border-radius: 4px;
      padding: 0 37px;
      box-sizing: border-box;
      .avatar {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #ffffff;
        margin: -60px auto 0;
        .el-avatar {
          width: 100px;
          height: 100px;
          margin: 10px;
        }
      }
      .userinfo {
        text-align: center;
        margin-top: 12px;
        .info-name {
          font-size: 16px;
          font-weight: bold;
          color: #198af7;
        }
        .info-sex {
          font-size: 14px;
          color: #646464;
          font-weight: 400;
          margin-left: 7px;
        }
      }
      .school {
        text-align: center;
        margin-top: 21px;
        padding-bottom: 19px;
        border-bottom: 1px solid #ccdef4;
        .school-name {
          font-size: 16px;
          font-weight: bold;
          color: #198af7;
        }
        .school-class {
          font-size: 16px;
          font-weight: bold;
          color: #198af7;
          margin-left: 20px;
        }
      }
      .el-row {
        height: 491px;
        .el-col {
          height: 100%;
          padding-top: 24px;
          position: relative;
          .message {
            font-size: 14px;
            color: #646464;
            line-height: 36px;
            font-weight: 400;
          }
          .btn {
            width: 100%;
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
          }
          .class-chart {
            width: 260px;
            height: 248px;
            margin: 0 auto;
          }
          .chart-show {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            cursor: pointer;
            h2 {
              font-size: 40px;
              color: #198af7;
              font-weight: 400;
              margin: 9px 0;
              span {
                font-size: 14px;
              }
            }
            p {
              font-size: 12px;
              color: #198af7;
              margin: 9px 0;
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.el-form-item {
  margin-bottom: 16px;
}
.el-form-item >>> .el-form-item__label {
  font-weight: bold;
  color: #303133;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.el-avatar {
  width: 120px;
  height: 120px;
}
.el-form >>> .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.el-form >>> .el-upload-list__item {
  width: 100px;
  height: 100px;
}
</style>