import {post} from '@/utils/axios'

export const updatePhone = params => post('/shida-user-center/SmsYzm/updatePhone',params)

export const getPhoneCode = params => post('/shida-user-center/SmsYzm/getYzm',params)

export const updatePassword = params => post('/shida-user-center/SysUser/updatePassword',params)

export const uploadImg = params => post('/shida-user-biz/upload/uploadHeadImage',params)

export const getCredit = params => post('/shida-user-biz/TbUserCreditRecord/getCreditByUserId', params)

// 申请特殊学生
export const applySpecial = params => post('/shida-user-biz/TbSpecial/saveOrUpdate',params)
// 申请详情
export const applySpecialDetail = params => post('/shida-user-biz/TbSpecial/getData',params)
// 重新申请
export const reApplySpecial = params => post('/shida-user-biz/TbSpecial/reApplySpecial',params)
// 撤销申请
export const cancelApplySpecial = params => post('/shida-user-biz/TbSpecial/revokeSpecial',params)
// 获取状态
export const applySpecialStatus = params => post('/shida-user-biz/TbSpecial/getSpecialStatus',params)